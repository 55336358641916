export default {
    shopType:[
        "BEAUTY SALON /SKINCARE / WOMEN SPA",
        "BOOKS/STATIONERY/TOYS/GIFTS/GAMES",
        "CHOCOLATE/ICE CREAM/CONFECTIONERY",
        "DEPARTMENT STORE",
        "DINING / RESTAURANTS",
        "DINING /CAFÉS",
        "DINING/RESTAURANTS",
        "ELECTRONICS/MOBILE PHONES",
        "ENTERTAINMENT",
        "ENTERTAINMENT/ CINEMA / GYM",
        "FASHION / ACCESSORIES",
        "FASHION / CHILDREN & MATERNITY",
        "FASHION / WOMEN",
        "FASHION /MEN",
        "FASHION/SPORTSWEAR",
        "FASHION/WOMEN",
        "FOOD COURT",
        "FOOTWEAR/LEATHER GOODS/BAGS",
        "GOVERNMENT SERVICES",
        "HEALTH & BEAUTY",
        "HOME FURNISHING & ACCESSORIES",
        "HYPERMARKET",
        "JEWELLERY / WATCHES & ACCESSORIES",
        "KIDS SALOON",
        "LINGERIE & SWIMWEAR",
        "MEN SALON",
        "MUSEUM & EXHIBITION",
        "OPTICS & SUNGLASSES",
        "PERFUMES",
        "PERFUMES/COSMETICS",
        "SERVICES / CDM/ATM",
        "SERVICES / MEDICAL & PHARMACIES",
        "SERVICES /TELECOMMUNICATIONS",
        "SERVICES/ BANKS & FINANCIAL SERVICES",
        "SPECIALTY STORES"
    ],
    location:[
            "B",
            "GL",
            "K B",
            "K GL",
            "K L1",
            "K L2",
            "K ML",
            "K SL",
            "L1",
            "L2",
            "ML",
            "P",
            "P L2",
            "SL",
    ],
    nationalities: [
        "Afghan",
        "Albanian",
        "Algerian",
        "American",
        "Andorran",
        "Angolan",
        "Antiguans",
        "Argentinean",
        "Armenian",
        "Australian",
        "Austrian",
        "Azerbaijani",
        "Bahamian",
        "Bahraini",
        "Bangladeshi",
        "Barbadian",
        "Barbudans",
        "Batswana",
        "Belarusian",
        "Belgian",
        "Belizean",
        "Beninese",
        "Bhutanese",
        "Bolivian",
        "Bosnian",
        "Brazilian",
        "British",
        "Bruneian",
        "Bulgarian",
        "Burkinabe",
        "Burmese",
        "Burundian",
        "Cambodian",
        "Cameroonian",
        "Canadian",
        "Cape Verdean",
        "Central African",
        "Chadian",
        "Chilean",
        "Chinese",
        "Colombian",
        "Comoran",
        "Comoros",
        "Congolese",
        "Costa Rican",
        "Croatian",
        "Cuban",
        "Cypriot",
        "Czech",
        "Danish",
        "Djibouti",
        "Dominican",
        "Dutch",
        "East Timorese",
        "Ecuadorean",
        "Egyptian",
        "Emirati",
        "Equatorial Guinean",
        "Eritrean",
        "Estonian",
        "Ethiopian",
        "Fijian",
        "Filipino",
        "Finnish",
        "French",
        "Gabonese",
        "Gambian",
        "Georgian",
        "German",
        "Ghanaian",
        "Greek",
        "Grenadian",
        "Guatemalan",
        "Guinea-Bissauan",
        "Guinean",
        "Guyanese",
        "Haitian",
        "Herzegovinian",
        "Honduran",
        "Hungarian",
        "I-Kiribati",
        "Icelander",
        "Indian",
        "Indonesian",
        "Iranian",
        "Iraqi",
        "Irish",
        "Israeli",
        "Italian",
        "Ivorian",
        "Jamaican",
        "Japanese",
        "Jordanian",
        "Kazakhstani",
        "Kenyan",
        "Kittian and Nevisian",
        "Kuwaiti",
        "Kyrgyz",
        "Laotian",
        "Latvian",
        "Lebanese",
        "Liberian",
        "Libyan",
        "Liechtensteiner",
        "Lithuanian",
        "Luxembourger",
        "Macedonian",
        "Malagasy",
        "Malawian",
        "Malaysian",
        "Maldivan",
        "Malian",
        "Maltese",
        "Marshallese",
        "Mauritanian",
        "Mauritian",
        "Mexican",
        "Micronesian",
        "Moldovan",
        "Monacan",
        "Mongolian",
        "Moroccan",
        "Mosotho",
        "Motswana",
        "Mozambican",
        "Namibian",
        "Nauruan",
        "Nepalese",
        "New Zealander",
        "Nicaraguan",
        "Nigerian",
        "Nigerien",
        "North Korean",
        "Northern Irish",
        "Norwegian",
        "Omani",
        "Pakistani",
        "Palauan",
        "Palestinian",
        "Panamanian",
        "Papua New Guinean",
        "Paraguayan",
        "Peruvian",
        "Polish",
        "Portuguese",
        "Qatari",
        "Romanian",
        "Russian",
        "Rwandan",
        "Saint Lucian",
        "Salvadoran",
        "Samoan",
        "San Marinese",
        "Sao Tomean",
        "Saudi",
        "Scottish",
        "Senegalese",
        "Serbian",
        "Seychellois",
        "Sierra Leonean",
        "Singaporean",
        "Slovakian",
        "Slovenian",
        "Solomon Islander",
        "Somali",
        "South African",
        "South Korean",
        "Spanish",
        "Sri Lankan",
        "Sudanese",
        "Surinamer",
        "Swazi",
        "Swedish",
        "Swiss",
        "Syrian",
        "Taiwanese",
        "Tajik",
        "Tanzanian",
        "Thai",
        "Togolese",
        "Tongan",
        "Trinidadian or Tobagonian",
        "Tunisian",
        "Turkish",
        "Tuvaluan",
        "Ugandan",
        "Ukrainian",
        "Uruguayan",
        "Uzbekistani",
        "Venezuelan",
        "Vietnamese",
        "Welsh",
        "Yemenite",
        "Zambian",
        "Zimbabwean"
    ],
    cityList: [
        {
            "name": "Al Ain"
        },
        {
            "name": "Al Bahia"
        },
        {
            "name": "Al Bateen"
        },
        {
            "name": "Al Dhafrah"
        },
        {
            "name": "Al Danah city"
        },
        {
            "name": "Al Falah"
        },
        {
            "name": "Al Adlah"
        },
        {
            "name": "Al Karamah"
        },
        {
            "name": "Al Khubeirah"
        },
        {
            "name": "Al Manaseer"
        },
        {
            "name": "Al Markaziyah (Central Abu Dhabi)"
        },
        {
            "name": "Al Maryah Island"
        },
        {
            "name": "Al Mina"
        },
        {
            "name": "Al Muneera"
        },
        {
            "name": "Al Mushrif"
        },
        {
            "name": "Al Nahyan"
        },
        {
            "name": "Al Qubesat"
        },
        {
            "name": "Al Raha Beach"
        },
        {
            "name": "Al Rawdah"
        },
        {
            "name": "Al Reef"
        },
        {
            "name": "Al Reem Island"
        },
        {
            "name": "Al Rowdah"
        },
        {
            "name": "Al Safarat"
        },
        {
            "name": "Al Samha"
        },
        {
            "name": "Al Shamkha"
        },
        {
            "name": "Al-Shahamah"
        },
        {
            "name": "Al Shawamekh"
        },
        {
            "name": "Al Shalila"
        },
        {
            "name": "Al Ghadeer"
        },
        {
            "name": "Al Wahda"
        },
        {
            "name": "Al Zahiyah"
        },
        {
            "name": "Bani Yas"
        },
        {
            "name": "Khalidiyah"
        },
        {
            "name": "Madinat Zayed"
        },
        {
            "name": "Marina Village"
        },
        {
            "name": "Masdar City"
        },
        {
            "name": "Mohamed Bin Zayed City"
        },
        {
            "name": "Madinat Al Riyad"
        },
        {
            "name": "Mussafah"
        },
        {
            "name": "Rabdan"
        },
        {
            "name": "Saadiyat Island"
        },
        {
            "name": "Tourist Club Area (TCA)"
        },
        {
            "name": "Yas Island"
        },
        {
            "name": "Al Hayer"
        },
        {
            "name": "Al Maqtaa"
        },
        {
            "name": "Al Muroor"
        },
        {
            "name": "Al Maffraq"
        },
        {
            "name": "Al Qurm"
        },
        {
            "name": "Al Rahbah"
        },
        {
            "name": "Al Zaab"
        },
        {
            "name": "Capital Centre"
        },
        {
            "name": "Corniche Road"
        },
        {
            "name": "Delma Street"
        },
        {
            "name": "Electra Street"
        },
        {
            "name": "Khalifa City"
        },
        {
            "name": "Mina Zayed"
        },
        {
            "name": "Najda Street"
        },
        {
            "name": "Officers City"
        },
        {
            "name": "Port Zayed"
        },
        {
            "name": "Rawdhat"
        },
        {
            "name": "Shakhbout City (previously called Khalifa City B)"
        },
        {
            "name": "Umm Al Nar"
        },
        {
            "name": "Al Wathba"
        },
        {
            "name": "Baynunah"
        },
        {
            "name": "Ghantoot"
        },
        {
            "name": "Hamdan Street"
        },
        {
            "name": "Hydra Village"
        },
        {
            "name": "Ras Al Akhdar"
        },
        {
            "name": "Ruwais"
        },
        {
            "name": "Rowdhat Abu Dhabi"
        },
        {
            "name": "Sas Al Nakhl Island"
        },
        {
            "name": "Shams Abu Dhabi"
        },
        {
            "name": "Zayed Sports City"
        },
        {
            "name": "Dubai"
        },
        {
            "name": "Sharjah"
        },
        {
            "name": "Ajman"
        },
        {
            "name": "Fujairah"
        },
        {
            "name": "Ras al-Khaimah"
        },
        {
            "name": "Umm al-Quwain"
        }
    ]
}