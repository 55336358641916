import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import { showLoading, hideLoading } from "../redux/alertsSlice";
import { toast } from "react-hot-toast";
import axios from "axios";
import { Table,Modal, Button,Input } from "antd";
import moment from "moment";
import { DataGrid, GridActionsCellItem, GridRowsProp, GridColDef,DateField  } from '@mui/x-data-grid';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import VerifiedIcon from '@mui/icons-material/Verified';
import EditIcon from '@mui/icons-material/Edit';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf'
import Addcampaign from "../components/AddCampaignForm";
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {Link, useNavigate} from 'react-router-dom';
var QRCode = require('qrcode');
const { Search } = Input;


function CampaignRegistrationList() {
  const [campaigns, setCampaigns] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [rerender, setRerender] = useState(false);
  const [open, setOpen] = useState(false);
  const [raffleOpen, setRaffleOpen] = useState(false);
  const [winners,setWinners] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('');
  const [currentCampaignData,setCurrentCampaignData] = useState(null)
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0
  });
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const getCampaignsData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/campaign/getCampaignRegistrations", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setCampaigns(response.data.data.campaignRegistrations);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const deleteRegistration = async (campaignRegistrationId) => {
    try {
      dispatch(showLoading());
      const response = await axios.post("/campaign/removeCampaignRegistration",
        { "campaignRegistrationId": campaignRegistrationId['campaignRegistrationId'],campaignId:campaignRegistrationId['campaignId'],participantId:campaignRegistrationId['participantId'] }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success("campaign Registration Deletion Success");
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Internal Server Error");
    }
  };
 
  const triggerRegistrationremove = (campaignData)=>{
    setModalText("reset")
    setCurrentCampaignData(campaignData);
    setShowPopup(true);
  }
  const handleConfirmRaffle = () => {
    deleteRegistration(currentCampaignData)
      setShowPopup(false);
    
  };
  
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setModalText('Please wait');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleRaffleCancel = () => {
    console.log('Clicked cancel button');
    setRaffleOpen(false);
  };

  
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: "Actions",
      width: 180,
      getActions: (params) => [
        <GridActionsCellItem
        icon={<RestartAltIcon />}
        label="Reset Raffle"
        hidden={user !== null && user.isAdmin === true ? false : true}
        // disabled={params.row.isRaffleOver === true ? true : false}
        onClick={(e) => { triggerRegistrationremove(params.row, e)}}
      />
      ]
    },
    { field: "campaignRegistrationId", headerName: "Registration Id", width: 120 },
    { field: "campaignId", headerName: "Campaign Id", width: 120 },
    { field: "participantId", headerName: " Name", width: 180 },
    { field: "totalAmount", headerName: "Total Amount", width: 150 },
    { field: "isValid", headerName: "status", width: 130,},
    {field: "updatedBy", headerName: "updated By", width: 180},
    { field: 'updatedAt', headerName:"Updated At", width: 120,  valueFormatter: params => 
    moment(params?.value).format("DD/MM/YYYY"),}
  ];
  const handleInputChange = async (inputValue) => {
    try {
      setCampaigns([]);
      const response = await axios.get('/campaign/CampaignRegistrationsdata', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { q: inputValue }
      });
      
      if (response.status === 200)
      {
        setCampaigns(response.data.data);

      }
      
    } catch (error) {
      console.error(error);
    }
  };
  const handlePhoneInputChange = async (inputValue) => {
    try {
      setCampaigns([]);
      const response = await axios.get('/campaign/CampaignRegistrationsByPhone', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { q: inputValue }
      });
      if (response.status === 200)
      {
        setCampaigns(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  
  useEffect(() => {
    // getCampaignsData();
  }, []);
  return <Layout>
    <div className="campaign-list">
    <Search className="search-input-reg" id="search-input" placeholder="Enter Search Id" onSearch={handleInputChange} enterButton /> 
    <Search className="search-input-reg" id="phone-input" placeholder="Enter Phone Number" onSearch={handlePhoneInputChange} enterButton />
    {campaigns.length > 0 && <DataGrid rows={campaigns} columns={columns}
      autoHeight
      disableRowSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'updatedAt', sort: 'desc' }],
        },
      }}
      // hideFooter
      showCellVerticalBorder
      showColumnVerticalBorder
      disableColumnReorderget
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[10, 20]}
      getRowId={(row) => row._id} />
    }
      
      <Modal
        title="Confirm Registration Disable"
        open={showPopup}
        destroyOnClose
        onOk={handleConfirmRaffle}
        onCancel={()=>{ setShowPopup(false)}}
      >
        Are you sure to remove the registration?
      </Modal>
     
      </div>
  </Layout>
}

export default CampaignRegistrationList;
