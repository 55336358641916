import React, { useState, useEffect } from 'react';
import { Button, message, Steps, notification, Col, Form, Input, Row, Select,Checkbox } from 'antd';
import { DatePicker, Space, InputNumber } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-hot-toast";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CONSTANTS from "../data.js"
import moment from "moment";
import Layout from "../components/Layout";
import { showLoading, hideLoading } from "../redux/alertsSlice";

const { Option } = Select;


const AddcampaignForm = (props) => {

  const { user, appData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [acuform] = Form.useForm();

  const userDetailChange = async (values) => {
    console.log(values, "User Details")
  }

  const savecampaign = async (campaignFormData) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/campaign/addCampaign",
        {
          ...campaignFormData,
          createdBy: user.name,
          sendSms: true,
          campaignId:props.campaignData.campaignId
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.status === 'success' || response.data.status === 'updated') {

        toast.success(response.data.status);
        props.triggerClose();
      } else {
        dispatch(hideLoading())
        toast.error(response.data.status);
        props.triggerClose();
      }
      window.location.reload()
    } catch (error) {
      toast.error("Something went wrong");
      props.triggerClose();
    }
  };

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Row>
      <Form form={acuform} id="acuForm"
        layout="vertical"
        onFieldsChange={userDetailChange}
        initialValues={props !== undefined  && props.campaignData}
        onFinish={savecampaign} style={{ marginBottom: "8em !important", padding: "1em !important" }}
      >
        <Row gutter={20}>
          <Col span={8} xs={24} sm={12} lg={12}>
            <Form.Item className="campaign-input"
              required
              label="Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item className="campaign-input"
              required
              label="Start Date"
              name="startDate"
              rules={[{ required: true }]}
            >
              <DatePicker onChange={onDateChange} />

            </Form.Item>
            <Form.Item className="campaign-input"
              required
              label="End Date"
              name="endDate"
              rules={[{ required: true }]}
            >
              <DatePicker onChange={onDateChange} />

            </Form.Item>
            <Form.Item className="campaign-input"
              required
              label="Type"
              name="type"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select type"
                allowClear  defaultValue="Shopping"
              >
                <Option value="Shopping">Shopping</Option>
              </Select>
            </Form.Item>
            <Form.Item className="campaign-input"
              label="No Of Winners"
              name="winnerLimit"
              rules={[{ required: true }]}
            >

              <InputNumber min={1} max={200} style={{ width: '100%' }} onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }} controls={false} />
            </Form.Item>
          </Col>
          <Col span={8} xs={24} sm={12} lg={12}>
            <Form.Item className="campaign-input"
              required
              label="Location"
              name="location"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Location"  defaultValue="Deerfields Mall"
                allowClear
              >
                <Option value="Deerfields">Deerfields Mall</Option>
              </Select>
            </Form.Item>
            <Form.Item className="campaign-input"
              label="Minimum Spend Amount"
              name="msAmount"
              rules={[{ required: true }]}
            >

              <InputNumber min={1} max={1000} style={{ width: '100%' }} onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }} controls={false} />
            </Form.Item>
            <Form.Item className="campaign-input"
              label="Voucher Per Minimum Spend"
              name="voucherPerMs"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} max={100} style={{ width: '100%' }} onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }} controls={false} />
            </Form.Item>
            <Form.Item className="campaign-input"
              label="Voucher Code Prefix"
              name="vcodePrefix"
              rules={[{ required: true }]}
            >
              <Input placeholder="Voucher Code Prefix" />
            </Form.Item>
            
            {/* <Form.Item className="campaign-input"
              // label="Is Active"
              name="isActive"
              valuePropName="checked"
            >
             <Checkbox>Is Active</Checkbox>
            </Form.Item>
            <Form.Item className="campaign-input"
              // label="Is Raffle Completed"
              name="isRaffleOver"
              valuePropName="checked"
            >
             <Checkbox>Is Raffle Over</Checkbox>
            </Form.Item> */}
          </Col>
        </Row>

        <Row>
        <Form.List name="storeVoucherConfig">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'store']}
                        rules={[{ required: true, message: 'Please Select Store' }]}
                      >
                        <Select
                          showSearch
                          allowClear
                          style={{ width: 150}}
                          id="store-input"
                          placeholder="Select Store"
                          optionFilterProp="children"
                          filterOption={filterOption}
                          options={(appData.shops || []).map((Sdata) => ({
                            value: Sdata.id +'|'+ Sdata.shopName +'|'+  Sdata.location +'|'+  Sdata.category,
                            label: Sdata.shopName
                          }))} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'amount']}
                        rules={[{ required: true, message: 'Please Enter Amount' }]}
                      >
                        <Input placeholder="Amount" onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'couponCount']}
                        rules={[{ required: true, message: 'Please Enter Coupon Count ' }]}
                      >
                        <Input placeholder="Coupon Count" onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" style={{ width: 300 }} onClick={() => add()} block icon={<PlusOutlined />}>
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
        </Row>
      </Form>
    </Row>
  );
};

export default AddcampaignForm;