import { createSlice } from "@reduxjs/toolkit";

export const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    user: null,
    bookingStep:0,
    bookingUser:null,
    bookingStatus:null,
    bookingId:null,
    participantData:null
  },
  reducers: {
    setUser: (state , action) => {
      state.user = action.payload;
    },
    setBookingstep: (state , action) => {
        state.bookingStep = action.payload;
    },
    setBookinguser: (state , action) => {
        state.bookingUser = action.payload;
    },
    setBookingStatus: (state , action) => {
        state.bookingStatus = action.payload;
    },
    setCurrentParticipantData: (state , action) => {
      state.participantData = action.payload;
    }

  },
});

export const { setUser , setBookingstep,setBookinguser,setBookingStatus,setCurrentParticipantData  } = bookingSlice.actions;