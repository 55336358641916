import { Button,Divider, Form, Input, Layout } from "antd";
import React, { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { hideLoading, showLoading } from "../redux/alertsSlice";
import Layoutc from "../components/Layout";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';


const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const isValid = isPhoneValid(phone);


  const onFinish = async (values) => {
    try {
      dispatch(showLoading());
      const response = await axios.post("/api/user/login", values);
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        localStorage.setItem("token", response.data.data);
        localStorage.setItem("userType","user")
        navigate("/booking");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something went wrong");
    }
  };


  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.setItem("userType","user")
      navigate("/booking");
    }
  }, []);

  return (
    <Layoutc>
      <div className="authentication">
        <div className="authentication-form card p-3">
          <h1 className="card-title">Login</h1>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label="User Name" rules={[{
            required: true
          }]} name="name">
            <Input placeholder="User Name" maxLength={50} rules={[{ required: true }]}/>
            {/* <PhoneInput
                defaultCountry="ae"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              /> */}
               {/* {!isValid && <div style={{ color: 'red' }}>Phone is not valid</div>} */}
          </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              
            >
              <Input.Password className="hide-border" />
            </Form.Item>


            <Button className="primary-button my-2 full-width-button btn-custom-login" htmlType="submit">
              LOGIN
            </Button>
            <Link to="/forgot-password" className="anchor mt-2">
              FORGOT PASSWORD?
            </Link>
          </Form>
        </div>

      </div>
    </Layoutc>
  );
}

export default Login;
