import { Button, Form, Input, Row, Col} from "antd";
import React,{ useEffect, useState }  from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { hideLoading, showLoading } from "../redux/alertsSlice";
import Layout from "../components/Layout";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const isValid = isPhoneValid(phone);

  const onFinish = async (values) => {
    try {
      dispatch(showLoading());
      const response = await axios.post("/api/user/register", values);
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/admin/userslist");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Something went wrong");
    }
  };

  
  const validatePassword = (_, value) => {
    if (value && !/[A-Z]/.test(value)) {
      return Promise.reject(new Error('Password must have at least one uppercase letter'));
    }

    if (value && !/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return Promise.reject(new Error('Password must have at least one special character'));
    }

    if (value && value.length < 8) {
      return Promise.reject(new Error('Password must have at least 8 characters'));
    }

    return Promise.resolve();
  };
  return (
    <Layout>
   
      <div className="register-form ">
      <Col  xs={24} sm={24} lg={{ span: 12, offset: 6 }}>
        <Form layout="vertical" onFinish={onFinish}>
        <h1 className="card-title">Register</h1>
          <Row>
            <Col  xs={24} sm={24} lg={12}>
          <Form.Item label="Name" name="name">
            <Input placeholder="Name" required rules={[{ required: true }]} />
          </Form.Item>
          <Form.Item label="Phone Number" name="phoneNumber" rules={[{ required: true }]}>
            {/* <Input placeholder="Phone number" maxLength={20} rules={[{ required: true }]}/> */}
            <PhoneInput
                defaultCountry="ae"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
          {/* {!isValid && <div style={{ color: 'red' }}>Phone is not valid</div>} */}
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input placeholder="Email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required rules={[{ required: true }]} />
          </Form.Item>
          </Col>
          <Col  xs={24} sm={24} lg={12}>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              { validator: validatePassword }
            ]}
            hasFeedback
          >
            <Input.Password className="hide-border" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password className="hide-border" />
          </Form.Item>
          </Col>
          </Row>
          <Button
            className="primary-button my-2 full-width-button btn-custom-reg"
            htmlType="submit"
          >
            REGISTER
          </Button>
          {/* <Link to="/login" className="anchor mt-2">
            CLICK HERE TO LOGIN
          </Link> */}
        </Form>
      </Col>
      </div>
  
    </Layout>
  );
}

export default Register;


