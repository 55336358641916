import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Layout from "../components/Layout";
import { Card, Col, Row, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { showLoadingR, hideLoadingR } from "../redux/alertsSlice";
import moment from "moment";
import { CardContent } from '@mui/material';
import ConfettiExplosion from 'react-confetti-explosion';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const Raffle = (props) => {
  const [visibleItems, setVisibleItems] = useState([]);
  const [raffleResult, setRaffleResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isExploding, setIsExploding] = React.useState(false);
  const [count, setCount] = useState(10);

  const dispatch = useDispatch();
  const location = useLocation();

  console.log(location)

  const fetchData = async () => {
    try {

      dispatch(showLoadingR());
      const response = await axios.post("/campaign/raffle",
        { "numberOfWinners": location.state.limit, "campaignId": location.state.id }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log(response, "response")
      if (response.data.success) {
        setRaffleResult(response.data.data)
        if(count === 0)
        {
          dispatch(hideLoadingR());
          setVisibleItems(raffleResult);
          setLoading(false)
          setIsExploding(true)
        }
        // setTimeout(() => {
        //   setLoading(false)
        //   setIsExploding(true)
        // }, 5000);
      }
    } catch (error) {
      dispatch(hideLoadingR());
    }
  };
  useEffect(() => {
    console.log(count)
    if (count === 0) {
      dispatch(hideLoadingR());
      setVisibleItems(raffleResult);
      setLoading(false)
      setIsExploding(true)
    }
    const timer =
      count > 0 && setInterval(() => setCount(count - 1), 2000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount

  }, [count]);
  useEffect(() => {
    fetchData();
  }, []);

  const convertToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(visibleItems);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    let currentDateDetails = moment().format('DD/MM/YYYY HH:mm');
    saveAs(data, location.state.name + '-winners-' + currentDateDetails + '.xlsx');
  };



  return (
    <Layout>
      <div className="raffle-list">
        <div className='raffle-page-name'>
          <h3 style={{ color: 'purple',marginRight: "1em" }}>{location.state.name}</h3>
          {visibleItems.length > 0 && !loading &&
            <Button type="primary" icon={<DownloadOutlined />} size={'Default'} onClick={() => { convertToExcel() }} />
          }
        </div>

        {count !== 0 &&
          // <div class="countdown">
          //   <span class="count">{count}</span>
          // </div>
          <div className="countdown-container">
          <div className="countdown">
            <div className="flip">{count}</div>
          </div>
        </div>
        }
        {isExploding && visibleItems.length > 0 && <ConfettiExplosion width={3000} particleCount={350} duration={3000} force={1} />}
        <br />
        
        {visibleItems.length > 0 && count === 0 &&
            <div className='raffle-container'>
              <img src="/winners.png" alt="Congratulations!" className="img-cong" />
              <br />
              <br />
              <Row gutter={16} className="winner-list">{visibleItems.map(item => (
                <Col span={8}>
                  <Card size="small" title={"Coupon- " + item.voucherId} bordered={true} loading={loading}>
                    <CardContent loading={loading}>
                      <h3><b>Name - {item.name}</b></h3>
													   
                    </CardContent>
                  </Card>
                </Col>
              ))}
              </Row>
            </div>
          }

      </div>
    </Layout>
  );
};

export default Raffle;