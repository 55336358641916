import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { Button } from "antd";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import Userslist from "./pages/Admin/Userslist";
import Booking from "./pages/booking";
import Profile from "./pages/Profile";
import Appointments from "./pages/Appointments";
import EmailVerify from "./pages/ForgotPassword/index";
import ForgotPassword from "./pages/ForgotPassword/index";
import PasswordReset from "./pages/PasswordReset/index";
import Faq from "./pages/Faq";
import AddCampaign from "./pages/AddCampaign";
import AddShop from "./pages/AddShop";
import ShopList from "./pages/ShopList";
import CampaignList from "./pages/CampaignList";
import CampaignRegistrationList from "./pages/CampaignRegistrationList";
import Raffle from "./pages/Raffle";
import CampaignRegistration from "./components/CampaignRegistration";
import UpdateConfig from "./pages/UpdateConfig";

function App() {
  const { loading,rloading } = useSelector((state) => state.alerts);
  return (
    <BrowserRouter>
      {/* {loading && (
        <div className="spinner-parent">
          <div className="spinner-border" role="status"></div>
        </div>
      )} */}
      {
        rloading && (
          <div className="lds-hourglass"></div>
        )
      }

      {loading && (
        <div className="spinner-parent">
          <div className="spinner-border" role="status"></div>
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/users/:id/verify/:token"
          element={
            <PublicRoute>
              <EmailVerify />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/password-reset/:id/:token"
          element={
            <PublicRoute>
              <PasswordReset />
            </PublicRoute>
          }
        /> 
        <Route
          path="/faq"
          element={
            <PublicRoute>
              <Faq />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/admin/register"
          element={
            <ProtectedRoute>
              <Register />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking"
          element={
            <ProtectedRoute>
              <Booking />
            </ProtectedRoute>      
          }
        />
         <Route
          path="/raffle"
          element={
            <ProtectedRoute>
              <Raffle />
            </ProtectedRoute>      
          }
        />
         <Route
          path="/admin/campaignList"
          element={
            <ProtectedRoute>
              <CampaignList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/addCampaign"
          element={
            <ProtectedRoute>
              <AddCampaign />
            </ProtectedRoute>
          }
        />
          <Route
          path="/admin/addShop"
          element={
            <ProtectedRoute>
              <AddShop />
            </ProtectedRoute>
          }
        />
            <Route
          path="/admin/config"
          element={
            <ProtectedRoute>
              <UpdateConfig />
            </ProtectedRoute>
          }
        />
          <Route
          path="/admin/shopList"
          element={
            <ProtectedRoute>
              <ShopList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/userslist"
          element={
            <ProtectedRoute>
              <Userslist />
            </ProtectedRoute>
          }
        />
  
        <Route
          path="/user/appointments"
          element={
            <ProtectedRoute>
              <Appointments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/appointments"
          element={
            <ProtectedRoute>
              <Appointments />
            </ProtectedRoute>
          }
        />
           <Route
          path="/admin/campaignRegistrationList"
          element={
            <ProtectedRoute>
              <CampaignRegistrationList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
