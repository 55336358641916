import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    profile:null,
    campaignList: null,
    appData:null
  },
  reducers: {
    setUser: (state , action) => {
      state.user = action.payload.userInfo;
      state.profile = action.payload.profileInfo
    },
    setAppData:(state , action) => {
      state.appData = action.payload
    },
  },
});

export const { setUser , setAppData, reloadUserData } = userSlice.actions;