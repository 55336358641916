import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import { showLoading, hideLoading } from "../redux/alertsSlice";
import { toast } from "react-hot-toast";
import axios from "axios";
import { Table } from "antd";
import moment from "moment";
import { DataGrid, GridActionsCellItem, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import VerifiedIcon from '@mui/icons-material/Verified';
import { jsPDF } from 'jspdf'
var QRCode = require('qrcode');

function Appointments() {
  const [appointments, setAppointments] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [rerender, setRerender] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 1
  });
  const dispatch = useDispatch();

  const getAppointmentsData = async () => {
    try {
      dispatch(showLoading());
      const resposne = await axios.get("/api/user/get-appointments-by-user-id", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (resposne.data.success) {
        setAppointments(resposne.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const generatePDF = async (value) => {

    let bookingRowData = value;

    delete bookingRowData["razorpayDetails"];
    delete bookingRowData["_id"];
    delete bookingRowData["createdAt"];
    delete bookingRowData["updatedAt"];
    delete bookingRowData["__v"];
    delete bookingRowData["userId"]
    delete bookingRowData["success"];

    let imgUrl = await QRCode.toDataURL(JSON.stringify(bookingRowData));

    // Defines the pdf
    let pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4"
    })

    pdf.setFontSize(20);
    pdf.setTextColor(255, 0, 0);
    pdf.text(35, 30, "Deer Fields Confirmation")
    pdf.setFontSize(10);
    pdf.setTextColor(0, 25, 51);
    pdf.text(35, 50, "Darshan Date:   " + value.bookingDate)
    pdf.text(35, 60, "Darshan ID:   " + value.bookingId)
    pdf.text(35, 70, "No of Devotees:   " + value.bookingQuantity)
    pdf.text(35, 80, "Type:   " + value.bookingType)
    pdf.text(35, 90, "Name:   " + value.bookingName)
    pdf.text(35, 100, "Amount:    " + value.bookingAmount)
    pdf.text(35, 110, "Email:   " + value.bookingEmail)
    pdf.text(35, 120, "Phone Number:    " + value.bookingContactNo)

    // Adds the image to the pdf
    pdf.addImage(imgUrl, 'png', 35, 130)

    // Downloads the pdf
    pdf.save('Darshan-' + value.bookingId + '.pdf')

  }
  const updateVisit = async (bookingData) => {
    try {
      dispatch(showLoading())
      const response = await axios.post(
        "/booking/updateVisit",
        { token: localStorage.getItem("token"),
          ...bookingData
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());

      let verificationStatus = response.data;
      if (verificationStatus.status === true) {
        getAppointmentsData()
      } else {
        setRerender(!rerender);
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error")
    }
  };


  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: "Get QR",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<QrCode2Icon />}
          label="Generate QR"
          onClick={(e) => { generatePDF(params.row, e) }}
        />,
        <GridActionsCellItem
          icon={<VerifiedIcon />}
          label="Generate QR"
          hidden={user.isAdmin === true ? false : true}
          onClick={(e) => { updateVisit(params.row, e)}}
        />
      ]
    },
    { field: "bookingDate", headerName: "Darshan Date", width: 180 },
    { field: "bookingId", headerName: " Darshan ID", width: 180 },
    { field: "bookingType", headerName: "Type", width: 80 },
    { field: "bookingQuantity", headerName: "count", width:  80},
    { field: "bookingName", headerName: "Name", width: 160 },
    {
      field: "visited", headerName: "Darshan Status", width: 130,type: 'boolean'
    },
    { field: "bookingContactNo", headerName: "Contact", width: 150 },
    { field: "bookingEmail", headerName: "Email", width: 300 },
    { field: "bookingAmount", headerName: "Cost", width: 70 },
    {
      field: "createdAt", headerName: "Booked On", width: 160,
      valueGetter: (params) => {
        return moment(params.value).format("DD-MM-YY , hh:mm a");
      }
    }
  ];


  useEffect(() => {
    getAppointmentsData();
  }, []);
  return <Layout>
    <h1 className="page-title">Appointments</h1>
    <hr />
    <DataGrid rows={appointments} columns={columns}
      autoHeight
      disableRowSelectionOnClick
      // hideFooter
      showCellVerticalBorder
      showColumnVerticalBorder
      disableColumnReorderget
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[10, 20]}
      getRowId={(row) => row._id} />
  </Layout>
}

export default Appointments;
