import React from 'react';
import { Col, Row, Divider } from 'antd';
import Layout from "../components/Layout";

const Faq = () => (
    <Layout>
        <Row>
            <Col span={12}>
                <Divider>Frequently Asked Questions</Divider>
            </Col>
            <Col span={24}>

                <p>Q. How to login</p>
                <p>A: Go to login page</p>
                <hr />
            </Col>
        </Row>
    </Layout>
);

export default Faq;
