import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import { showLoading, hideLoading } from "../redux/alertsSlice";
import axios from "axios";
import {Modal, Button } from "antd";
import moment from "moment";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Addcampaign from "../components/AddCampaignForm";
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {Link, useNavigate} from 'react-router-dom';
import AddShop from "./AddShop";


function ShopList() {
  const [shopDetails, setshopDetails] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currentShopData,setCurrentShopData] = useState(null)
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 1
  });
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const getShopData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/shop/get-all-shops", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setshopDetails(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const updateShop = async (shopData) => {
    try {
      dispatch(showLoading())
      setCurrentShopData(shopData)
      showModal()
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error")
    }
  };


  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setModalText('Please wait');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  
  const columns = [
    { field: "id", headerName: " Shop Id", width: 180 },
    { field: "shopName", headerName: "Shop Name", width: 150 },
    { field: "arabicName", headerName: "Arabic Name", width: 130,},
    { field: "category", headerName: "Category", width:  200},
    { field: "location", headerName: "Location", width: 120 },
    { field: "active", type: 'boolean', headerName: "Is Active", width: 150 },
  ];


  useEffect(() => {
    getShopData();
  }, []);
  return <Layout>
    <div className="campaign-list">
    <Link to="/admin/addShop">
      <Button>Add Shop</Button>
    </Link>
    <hr />
    <DataGrid rows={shopDetails} columns={columns}
      autoHeight
      disableRowSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'createdAt', sort: 'desc' }],
        },
      }}
      // hideFooter
      showCellVerticalBorder
      showColumnVerticalBorder
      disableColumnReorderget
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[10, 20]}
      getRowId={(row) => row._id} />
       {/* <Modal
        title="Update Campaign Details"
        open={open}
        destroyOnClose
        // onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={null} 
      >
        <AddShop campaignData={currentShopData} triggerClose={handleOk}/>
      </Modal>      */}
      </div>
  </Layout>
}

export default ShopList;
