import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setUser,setAppData } from "../redux/userSlice";
import { showLoading, hideLoading } from "../redux/alertsSlice";
import { setBookinguser } from "../redux/bookingSlice";

function ProtectedRoute(props) {
  const { user,appData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCompaignData = async () => {
    if (localStorage.getItem("userType") !== "guest") {
    try {
      dispatch(showLoading());
      const response = await axios.get("/campaign/getCampaignPlatformdata", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        dispatch(setAppData(response.data.data))
      }
    } 
    catch (error) {
      dispatch(hideLoading());
    }
    }
  };
  const getUser = async () => {
    if (localStorage.getItem("userType") !== "guest") {
      try {
        dispatch(showLoading())

        const response = await axios.post(
          "/api/user/get-user-info-by-id",
          { token: localStorage.getItem("token") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        dispatch(hideLoading());
        if (response.data.success) {
          dispatch(setUser(response.data.data));
          let profileData = response.data.data.profileInfo;
          let userObj = {
            name: profileData.name,
            phoneNumber: profileData.phoneNumber,
            address: profileData.place,
            email: profileData.email
          }
          dispatch(setBookinguser(userObj))
        }
        else {
          localStorage.clear()
          navigate("/login");
        }
      } catch (error) {
        dispatch(hideLoading());
        localStorage.clear()
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (!user) {
      getUser();
    }
    if (!appData){
      getCompaignData();
    }
  }, [user,appData]);

  // if (localStorage.getItem("token")) {
  return props.children;
  // } else {
  //   return <Navigate to="/login" />;
  // }
}

export default ProtectedRoute;
