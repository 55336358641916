import React, { useState, useEffect } from 'react';
import { Button, message, Steps,notification } from 'antd';
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Layout from "../components/Layout";
import ParticipantRegistration from "../components/ParticipantRegistration";
import CampaignRegistration from "../components/CampaignRegistration";
import { setBookingstep, setBookingStatus } from "../redux/bookingSlice";
import { showLoading, hideLoading } from "../redux/alertsSlice";

const steps = [

  {
    title: 'Register Participant',
    content: <ParticipantRegistration />,
  },
  {
    title: 'Campaign Registration',
    content: <CampaignRegistration />,
  }

];

const Booking = (props) => {

  const { bookingStep, bookingCost, bookingQuantity, bookingType, bookingUser, bookingDate } = useSelector((state) => state.booking);
  const { user } = useSelector((state) => state.user);
  const [api, contextHolder] = notification.useNotification();
  const [current, setCurrent] = useState(bookingStep);
  const dispatch = useDispatch();


  useEffect(() => { setCurrent(bookingStep) }, []);

  
  const openNotificationWithIcon = (type,reason) => {
    api[type]({
      message: 'Booking / Payment Failure',
      description: reason
    });
  };

  const next = () => {
    // setCurrent(current + 1);
    dispatch(setBookingstep(bookingStep + 1));
  };
  const onChange = (value) => {
    dispatch(setBookingstep(value));
  };
  const prev = () => {
    // setCurrent(current - 1);
    dispatch(setBookingstep(bookingStep - 1));
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle = {
    // lineHeight: '260px',
    // textAlign: 'center',
    // border: `1px dashed red`,
    // paddingTop: '50px',
  };
  const contentStyleb = {
    // lineHeight: '260px',
    // textAlign: 'center',
    // border: `1px dashed red`,
    paddingTop: '0',
  };


  return (
    <Layout>
      {contextHolder}
      <Steps current={bookingStep} items={items}  onChange={onChange} />
      
      <div style={bookingStep === 1 ? contentStyleb : contentStyle}>{steps[bookingStep].content}</div>
     
    </Layout>
  );
};

export default Booking;