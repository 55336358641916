// src/App.js
import React, { useState } from 'react';
import axios from 'axios';
import Layout from "../components/Layout";
import { Button, Col, Form, Input, Row, TimePicker, Select, DatePicker, InputNumber } from "antd";

function UpdateConfig() {
    const [text, setText] = useState('');
    const [file, setFile] = useState(null);

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleTextSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/save-text', { text });
            alert(response.data.message);
        } catch (error) {
            alert('Error saving text');
        }
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert(response.data.message);
        } catch (error) {
            alert('Error uploading file');
        }
    };

    return (
        <Layout>
        <div>
            <h1>Upload Text and File</h1>
            <Row>
            <Col xs={24} sm={24} lg={24} className="config-form">
            <form onSubmit={handleTextSubmit}>
                <textarea value={text} onChange={handleTextChange} placeholder="Enter Subject" />
                <button type="submit">Save Subject</button>
            </form>
            </Col>
            <Col xs={24} sm={24} lg={24} className="config-form">
            <form onSubmit={handleFileUpload}>
                <div className="form-file-sec">
                <input type="file" onChange={handleFileChange} accept="image/jpeg" />
                <label for="files">Allowed only jpg file and name should be deerfieldsFooter.jpg</label>
                </div>
                <button type="submit">Upload Mail Image</button>
            </form>
            </Col>
            </Row>
        </div>
        </Layout>
    );
}

export default UpdateConfig;
