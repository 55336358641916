import { Button, Col, Form, Input, Row, TimePicker, Select, Card, Space, Alert } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CloseSquareFilled } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import { showLoading, hideLoading } from "../redux/alertsSlice";
import CONSTANTS from "../data.js"
import { toast } from "react-hot-toast";
import axios from "axios";
import { setBookingstep } from "../redux/bookingSlice";


const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

function CampaignRegistration({ onFinish, initivalValues }) {

  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [campaignData, setcampaignData] = useState([]);
  const [shops, setShopData] = useState([]);
  const [selectedCampaignData, setSelectedCampaignData] = useState(null);
  const [value, setValue] = useState('');
  const [registrationStatus, setRegistrationStatus] = useState(null)
  const [balanceData, setBalanceData] = useState(null)


  const { participantData } = useSelector((state) => state.booking);
  const { user, profile } = useSelector((state) => state.user);

  const [crForm] = Form.useForm();

  const getCompaignData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/campaign/getCampaignPlatformdata", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setcampaignData(response.data.data.campaigns);
        console.log("call last registration details");
        setShopData(response.data.data.shops)
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getCompaignData();
  }, []);
  const userDetailChange = async (values) => {
    console.log(values, "User Details")
  };

  //get latest registrationData
  const getLastRegistrationData = async (campaignId,ParticipantId) => {
    try {
      dispatch(showLoading());
      const response = await axios.post("/campaign/CampaignLastRegistrationBalance", 
        {
          "campaignId": campaignId,
          "participantId":ParticipantId
        },
        {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        if(response.data && response.data.data && response.data.data.length > 0)
        {
          let campaignRegData = response.data.data
          let balanceData = campaignRegData[campaignRegData.length-1]
          setBalanceData(balanceData)

        }
        console.log("api response get last reg"+response);
        //setBalanceData(response.data.data.balanceData);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
//end of get latest registration data


  const onCampaignSelection = (value) => {
   //get latest registrationData

   //end of get latest registration data
    const filteredCampaignData = campaignData.filter(cdata => {
      return cdata.campaignId === value
    });
    setSelectedCampaignData(filteredCampaignData[0]);
    getLastRegistrationData(value,participantData.participantId)
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const onAmountChange = (value) => {
    console.log('onAmountChange:', value);
  };

  const onStoreChange = (value) => {
    console.log('onAmountChange:', value);
  };

  const onSelect = (data) => {
    console.log('onSelect', data);
  };



  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const saveCampaignRegistration = async (campaignRegistrationData) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/campaign/campaignRegistration",
        {
          ...campaignRegistrationData,
          campaignName: selectedCampaignData.name,
          phoneNumber: participantData.phoneNumber,
          updatedBy: user.name,
          vcodePrefix: selectedCampaignData.vcodePrefix,
          msAmount: selectedCampaignData.msAmount,
          voucherPerMs: selectedCampaignData.voucherPerMs
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.status === 'success') {
        setRegistrationStatus(response.data)
        //toast.success(response.data.status);
      } else {
        dispatch(hideLoading())
        toast.error(response.data.status);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const closeRegistration = () => {
    dispatch(setBookingstep(0))
  }

  const getfilteredCamapaignData = () =>{
    let filteredCampaignDatavalues = [];
    if(campaignData.length === 0)
    {
      return filteredCampaignDatavalues;
    }
    else{
      campaignData.forEach(campaign => {
        const startDate = moment(campaign.startDate)
        const startOfDay = startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = moment(campaign.endDate)
        const endOfDay = endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss')
        console.log(startOfDay,endOfDay)
        if (moment(new Date()).isSameOrAfter(startOfDay) && moment(new Date()).isSameOrBefore(endOfDay)) {
          let optionval = {value: campaign.campaignId,label: campaign.campaignId + '-' + campaign.name}
          filteredCampaignDatavalues.push(optionval)
        } 
      });
      return filteredCampaignDatavalues;
    }
   
  }

  return (
    <>
      {
        registrationStatus === null &&
        <Form form={crForm} id="myForm"
          layout="vertical"
          onFieldsChange={userDetailChange}
          onFinish={saveCampaignRegistration}
        >
          <Row>
            <Col span={8} xs={24} sm={12} lg={12}>
              <Form.Item name="campaignId" label="Campaign" rules={[{ required: true }]}>
                <Select
                  placeholder="Select Campaign"
                  style={{ width: 300 }}
                  onChange={onCampaignSelection}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={getfilteredCamapaignData()}
                />
              </Form.Item>
              
              {selectedCampaignData !== null &&
                <Row className="add-space">
                  <Col> <p>Name: <b>{selectedCampaignData.name}</b></p>
                    <p>Start Date: <b>{moment(selectedCampaignData.startDate).format('DD-MMM-YYYY')}</b></p>
                    <p>Minimum Spend Per Coupon(AED): <b>{selectedCampaignData.msAmount}</b></p>
                  </Col>
                  <Col flex="auto"></Col>
                  <Col ><p>location: <b>{selectedCampaignData.location}</b></p>
                    <p>End Date: <b>{moment(selectedCampaignData.endDate).format('DD-MMM-YYYY')}</b></p>
                    <p>No Of Coupon: <b>{selectedCampaignData.voucherPerMs}</b></p></Col>
                </Row>
              }
            </Col>
            <Col span={8} xs={24} sm={12} lg={12}>
              <div>
                <p><b>Selected Participant:</b></p>
                <p>{participantData.firstName + ' ' + participantData.lastName}</p>
                <p>{participantData.phoneNumber}</p>
              </div>
            </Col>
            {balanceData !== null && <div className="add-space"><div>Previous Balance : {balanceData.balanceAmount} </div>
              <div>{balanceData.shopBalance && balanceData.shopBalance.map((item, i) => {
                  return (
                    <p>
                      {item.name} - {item.remainingBalance}
                    </p>
                  );
                })}
              </div></div>}
            <Col span={8} xs={24} sm={24} lg={24} className="add-space">
            <h6>Enter Receipt Details</h6>
            <Form.List name="receipts">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'store']}
                        rules={[{ required: true, message: 'Please Select Store' }]}
                      >
                        <Select
                          showSearch
                          allowClear
                          style={{ width: 300}}
                          id="store-input"
                          placeholder="Select Store"
                          onChange={onStoreChange}
                          onSearch={onSearch}
                          optionFilterProp="children"
                          filterOption={filterOption}
                          options={(shops|| []).map((Sdata) => ({
                            value: Sdata.id +'|'+ Sdata.shopName +'|'+  Sdata.location +'|'+  Sdata.category,
                            label: Sdata.shopName
                          }))} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'amount']}
                        rules={[{ required: true, message: 'Please Enter Amount' }]}
                      >
                        <Input placeholder="Amount" onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" style={{ width: 300 }} onClick={() => add()} block icon={<PlusOutlined />}>
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          </Row>
          
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
      {
        registrationStatus !== null && <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            message="Success"
            description={'Total Amount: ' + registrationStatus.totalAmount + ' , ' + 'Balance Amount: ' + registrationStatus.balanceAmount
              + ' , ' + 'No Of Vouchers: ' + registrationStatus.voucherCount + ' , ' + 'Voucher Numbers: ' + registrationStatus.voucherId}
            type="success"
            showIcon
            closable
            banner={true}
            onClose={() => { closeRegistration() }}
          />
        </Space>
      }
    </>
  );
}

export default CampaignRegistration;
