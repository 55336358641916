import React, { useState, useEffect } from 'react';
import { Button, message, Steps, notification, Col, Form, Input, Row, Select } from 'antd';
import { DatePicker, Space, InputNumber } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-hot-toast";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CONSTANTS from "../data.js"
import moment from "moment";
import Layout from "../components/Layout.js";
import { showLoading, hideLoading } from "../redux/alertsSlice.js";

const { Option } = Select;


const AddShop = (props) => {

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [acform] = Form.useForm();

  const userDetailChange = async (values) => {
    console.log(values, "User Details")
  }

  const saveShopDetails = async (shopData) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/shop/add-Store",
        {
          ...shopData
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.status === 'success') {
        toast.success(response.data.status);
      } else {
        dispatch(hideLoading())
        toast.error(response.data.status);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };
    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <Layout>
      <Form form={acform} id="acForm"
        layout="vertical"
        onFieldsChange={userDetailChange}
        onFinish={saveShopDetails} style={{ marginBottom: "8em !important", padding: "1em !important" }}
      >
        <h3 className="card-title mt-3">Enter Campaign</h3>
        <Row gutter={20}>
          <Col span={8} xs={24} sm={12} lg={6}>

          <Form.Item className="campaign-input"
              label="ShopId"
              name="id"
              rules={[{ required: true }]}
            >

              <InputNumber min={1} max={200} style={{ width: '100%' }} onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }} controls={false} />
            </Form.Item>
            <Form.Item className="campaign-input"
              required
              label="Shop Name"
              name="shopName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Shop Name" />
            </Form.Item>
            <Form.Item className="campaign-input"
              required
              label="Arabic Name"
              name="arabicName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Arabic Name" />
            </Form.Item>
            <Form.Item className="campaign-input"
              required
              label="location"
              name="location"
              rules={[{ required: true }]}
            >
                  <Select
                          showSearch
                          allowClear
                          style={{ width: 150}}
                          id="store-input"
                          placeholder="Select location"
                          optionFilterProp="children"
                          filterOption={filterOption}
                          options={(CONSTANTS.location || []).map((Sdata) => ({
                            value: Sdata,
                            label: Sdata
                          }))} />
            </Form.Item>

            <Form.Item className="campaign-input"
              required
              label="Category"
              name="category"
              rules={[{ required: true }]}
            >
              <Select
                          showSearch
                          allowClear
                          style={{ width: 150}}
                          id="store-input"
                          placeholder="Select Category"
                          optionFilterProp="children"
                          filterOption={filterOption}
                          options={(CONSTANTS.shopType || []).map((Sdata) => ({
                            value: Sdata,
                            label: Sdata
                          }))} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
        <Col span={8} xs={24} sm={12} lg={10}>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
        </Col>
        </Row>
      </Form>
    </Layout>
  );
};

export default AddShop;