import { Button, Col, Form, Input, Row, TimePicker, Select, DatePicker, InputNumber } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "../redux/alertsSlice";
import { setBookingstep,setCurrentParticipantData } from "../redux/bookingSlice";
import { toast } from "react-hot-toast";
import axios from "axios";
import dayjs from 'dayjs';
import { Country, State, City } from 'country-state-city';
import { PhoneInput } from 'react-international-phone';
import CONSTANTS from "../data.js"
import 'react-international-phone/style.css';

const { Option } = Select;

function ParticipantRegistration({ onFinish, initivalValues }) {

  const dispatch = useDispatch();
  const { user, profile } = useSelector((state) => state.user);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phone, setPhone] = useState('');
  const [participantData, setparticipantData] = useState([]);
  const [participantInitial, setparticipantInitial] = useState({})
  const [cityValue, setCityValue] = React.useState(null);
  const [participantValue, setParticipantValue] = React.useState(null);

  const [pform] = Form.useForm();

  const getCompaignData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/campaign/getCampaignPlatformdata", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setparticipantData(response.data.data.participants);
       
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
   // getCompaignData();
  }, []);
  const userDetailChange = async (values) => {
    console.log(values, "User Details")
  }
  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };
  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


  const onSearch = (value) => {
    console.log('search:', value);
  };
  const onCountrySelection = (value) => {
    setSelectedCountry(value.split('-')[1]);
    pform.setFieldsValue({ city: null })
  };
  const onCitySelection = (value) => {
    setCityValue(value)
  }
  const saveParticipant = async (participantData) => {
    try {
      dispatch(showLoading());
      const response = await axios.post(
        "/campaign/addParticipant",
        {
          ...participantData,
          participantId: participantInitial.participantId !== undefined ? participantInitial.participantId : undefined,
          createdBy: user.name
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(hideLoading());
      if (response.data.status === 'success' || response.data.status === 'updated') {
        toast.success(response.data.status);
        dispatch(hideLoading());
        let updatedParticipantData = {...participantData, participantId:participantInitial.participantId}
        dispatch(setCurrentParticipantData(updatedParticipantData));
        dispatch(setBookingstep(1));
      } else {
        dispatch(hideLoading())
        toast.error(response.data.status);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  
  const handleInputChange = async (inputValue) => {
    try {
      const response = await axios.get('/campaign/getparticipants', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { q: inputValue }
      });
      
      if (response.status === 200)
      {
        setparticipantData(response.data.data);

      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (newValue) => {
    setParticipantValue(newValue);
  };

  const onChange = (value) => {
    let selectedParticipant = participantData.filter(pdata => pdata.participantId === value);
    selectedParticipant[0].dob =  dayjs(selectedParticipant[0].dob);
    setparticipantInitial(selectedParticipant[0]);
    if(selectedParticipant[0].city === "Others")
    {
      setCityValue("Others");      
    }
    pform.setFieldsValue(selectedParticipant[0]);
  };
  return (
  <div className="participant-form">
            {/* <Select
              showSearch
              allowClear
              id="participantId"
              className="custom-select"
              style={{ width: 300, paddingLeft: "1em" }}
              placeholder="Select Participant"
              onChange={onChange}
              onSearch={handleInputChange}
              optionFilterProp="children"
              filterOption={filterOption}
              //onInputKeyDown	={handleInputChange}
              options={(participantData || []).map((pdata) => ({
                value: pdata.participantId,
                label: pdata.firstName + '-' + pdata.phoneNumber
              }))} /> */}
          
          <Select
      showSearch
      id="participantId"
              className="custom-select"
              style={{ width: 300, paddingLeft: "1em" }}
              placeholder="Select Participant"
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleInputChange}
      onChange={onChange}
      notFoundContent={null}
      options={(participantData || []).map((pdata) => ({
        value: pdata.participantId,
        label: pdata.firstName + '-' + pdata.phoneNumber
      }))}
    />
    <Form form={pform} id="pForm"
      layout="vertical"
      onFieldsChange={userDetailChange}
      onFinish={saveParticipant}
      initialValues={participantInitial}
    >
      <Row >
        <Col xs={24} sm={8} lg={8}>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="First Name"
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="Phone Number"
              name="phoneNumber"
              validateTrigger="onBlur"
              rules={[{ required: true },
                () => ({
                  validator(_, value) {
                    if (value.length < 9) {
                      return Promise.reject("Phone number can't be less than 9 digits");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <PhoneInput
                defaultCountry="ae"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="Gender"
              name="gender"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Gender"
                allowClear
              >
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="Country Of Residence"
              name="countryofResidence"
              rules={[{ required: true }]}
            >

              <Select
                placeholder="Select Country"
                onChange={onCountrySelection}
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                onSearch={onSearch}
                options={(Country.getAllCountries() || []).map((d) => ({
                  value: d.name + '-' + d.isoCode,
                  label: d.name + '-' + d.isoCode
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="Nationality"
              name="nationality"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select"
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                onSearch={onSearch}
                options={(CONSTANTS.nationalities || []).map((nationality) => ({
                  value: nationality,
                  label: nationality
                }))}
              />
            </Form.Item>
          </Col>
        </Col>
        <Col xs={24} sm={8} lg={8}>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="Last Name"
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="Date Of Birth"
              name="dob"
              rules={[{ required: true }]}
            >
              <DatePicker format="DD-MM-YYYY" onChange={onDateChange} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item className="devotee-input"
              required
              label="City"
              name="city"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select"
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                onSearch={onSearch}
                onChange={onCitySelection}
                options={[...(selectedCountry === "AE" ? CONSTANTS.cityList : City.getCitiesOfCountry(selectedCountry) || []).map((d) => ({
                  value: d.name,
                  label: d.name
                })),
                { label: "Others", value: "Others" }]}
              />
            </Form.Item>
            { cityValue === "Others" &&
                <Form.Item name="otherCity" label="Other City"  rules={[{ required: true }]}><Input placeholder="Other City" /></Form.Item>
              }
          </Col>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
}

export default ParticipantRegistration;
