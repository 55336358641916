import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../components/Layout"
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import 'react-international-phone/style.css';
import styles from "./styles.module.css";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [userName, setUserName] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");
	const [phone, setPhone] = useState('');
	const isValid = isPhoneValid(phone);


	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = `/api/password-reset`;
			const { data } = await axios.post(url, { name:userName });
			setMsg(data.message);
			setError("");
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		}
	};

	return (
		<Layout>
			<div className={styles.container}>
				<form className={styles.form_container} onSubmit={handleSubmit}>
					<h1>Forgot Password</h1>
						<input
						type="text"
						placeholder="User Name"
						name="userName"
						onChange={(e) => setUserName(e.target.value)}
						value={userName}
						required
						className={styles.input}
					/>
					{/* <input
						type="email"
						placeholder="Email"
						name="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						required
						className={styles.input}
					/> */}
					{/* <PhoneInput
						defaultCountry="ae"
						value={phone}
						required={true} forceDialCode={true}
						onChange={(phone) => setPhone(phone)}
					/>
					{!isValid && <div style={{ color: 'red' }}>Phone is not valid</div>} */}
					{error && <div className={styles.error_msg}>{error}</div>}
					{msg && <div className={styles.success_msg}>{msg}</div>}
					<button type="submit" className={styles.green_btn}>
						Submit
					</button>
					<Link to="/login" className="anchor mt-2">
						Login
					</Link>
				</form>
			</div>
		</Layout>
	);
};

export default ForgotPassword;
