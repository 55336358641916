import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/Layout";
import { showLoading, hideLoading } from "../redux/alertsSlice";
import { toast } from "react-hot-toast";
import axios from "axios";
import { Table,Modal, Button } from "antd";
import moment from "moment";
import { DataGrid, GridActionsCellItem, GridRowsProp, GridColDef,DateField  } from '@mui/x-data-grid';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import VerifiedIcon from '@mui/icons-material/Verified';
import EditIcon from '@mui/icons-material/Edit';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf'
import Addcampaign from "../components/AddCampaignForm";
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {Link, useNavigate} from 'react-router-dom';
var QRCode = require('qrcode');


function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [rerender, setRerender] = useState(false);
  const [open, setOpen] = useState(false);
  const [raffleOpen, setRaffleOpen] = useState(false);
  const [winners,setWinners] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('');
  const [currentCampaignData,setCurrentCampaignData] = useState(null)
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0
  });
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const getCampaignsData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get("/campaign/getCampaignPlatformdata", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setCampaigns(response.data.data.campaigns);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const resetRaffle = async (campaignId) => {
    try {
      dispatch(showLoading());
      const response = await axios.post("/campaign/resetRaffle",
        { "campaignId": campaignId }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success("Raffle Reset Success");
      }
    } catch (error) {
      dispatch(hideLoading());
      toast.error("Internal Server Error");
    }
  };
  const triggerRaffle = (campaignData)=>{
    setModalText("start")
    setCurrentCampaignData(campaignData);
    setShowPopup(true);
  }
  const triggerRaffleReset = (campaignData)=>{
    setModalText("reset")
    setCurrentCampaignData(campaignData['campaignId']);
    setShowPopup(true);
  }
  const navigateRaffle = async (campaignData) => {
    navigate(`/raffle?id=${campaignData['campaignId']}`,{state:{id:campaignData['campaignId'],name:campaignData['name'],limit:campaignData['winnerLimit']}});
  }
  const handleConfirmRaffle = () => {
    if (typeof currentCampaignData === 'string') {
      resetRaffle(currentCampaignData)
      setShowPopup(false);
    } else {
     navigateRaffle(currentCampaignData)
     setShowPopup(false);
    }
  };
  
  
  const convertToExcel = (json,cId,cName) => {
    const worksheet = XLSX.utils.json_to_sheet(json);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    let currentDateDetails =  moment().format('DD/MM/YYYY HH:mm');
    saveAs(data, cId + cName + '-report-'+currentDateDetails+'.xlsx');
  };

  const getReport = async (campaignData) => {
    
    try {
      dispatch(showLoading());
      const response = await axios.post("/campaign/getCampaignRegistrationReport",
      {"campaignId":campaignData['campaignId']}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(hideLoading());
      if (response.data.success) {
        console.log(response.data)
        let reportData= response.data.data.campaignRegistrationData
        if(reportData.length > 0)
        {
          convertToExcel(response.data.data.campaignRegistrationData,campaignData['campaignId'],campaignData['name']);
        }
        else{
          toast.error("No registrations found");
        }
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  }
  const updateCampaign = async (campaignData) => {
    try {
      dispatch(showLoading())
      let campaignDataObj = campaignData;
      campaignDataObj["startDate"]=dayjs(campaignData["startDate"])
      campaignDataObj["endDate"]= dayjs(campaignData["endDate"])
      console.log(campaignDataObj,campaignDataObj)
      setCurrentCampaignData(campaignDataObj)
      showModal()
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error")
    }
  };


  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setModalText('Please wait');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleRaffleCancel = () => {
    console.log('Clicked cancel button');
    setRaffleOpen(false);
  };

  
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: "Actions",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Update Info"
          onClick={(e) => { updateCampaign(params.row, e) }}
        />,
        <GridActionsCellItem
          icon={<EmojiEventsOutlinedIcon />}
          label="Raffle Draw"
          hidden={user !== null && user.isAdmin === true ? false : true}
          // disabled={params.row.isRaffleOver === true ? true : false}
          onClick={(e) => { triggerRaffle(params.row, e)}}
        />,
        <GridActionsCellItem
        icon={<RestartAltIcon />}
        label="Reset Raffle"
        hidden={user !== null && user.isAdmin === true ? false : true}
        // disabled={params.row.isRaffleOver === true ? true : false}
        onClick={(e) => { triggerRaffleReset(params.row, e)}}
      />,
        <GridActionsCellItem
          icon={<FileDownloadIcon />}
          label="Report"
          hidden={user !== null && user.isAdmin === true ? false : true}
          onClick={(e) => { getReport(params.row, e)}}
        />
      ]
    },
    { field: "campaignId", headerName: "Campaign ID", width: 120 },
    { field: "name", headerName: " Name", width: 180 },
    { field: "startDate", headerName: "Start Date", width: 150,
    valueFormatter: params => 
     moment(params?.value).format("DD/MM/YYYY"),},
    { field: "endDate", headerName: "End Date", width:  150,   valueFormatter: params => 
    moment(params?.value).format("DD/MM/YYYY"),},
    { field: "location", headerName: "Location", width: 150 },
    { field: "msAmount", headerName: "Minimum Spend Amount", width: 130,},
    { field: "voucherPerMs", headerName: "Voucher Per Minimum Spent", width: 120 },
    { field: "vcodePrefix", headerName: "Voucher Prefix", width: 120 },
    // { field: "isActive", type: 'boolean', headerName: "Is Active", width: 150 },
    // { field: "isRaffleOver", type: 'boolean', headerName: "Is Raffle Over", width: 150 },
    {field: "winnerLimit", headerName: "Winner Count", width: 120},
    { field: 'createdAt', headerName:"Created At", width: 120,  valueFormatter: params => 
    moment(params?.value).format("DD/MM/YYYY"),}
  ];


  useEffect(() => {
    getCampaignsData();
  }, []);
  return <Layout>
    <div className="campaign-list">
    <Link to="/admin/addCampaign">
      <Button>Create Campaign</Button>
    </Link>
    <hr />
    <DataGrid rows={campaigns} columns={columns}
      autoHeight
      disableRowSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [{ field: 'createdAt', sort: 'desc' }],
        },
      }}
      // hideFooter
      showCellVerticalBorder
      showColumnVerticalBorder
      disableColumnReorderget
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[10, 20]}
      getRowId={(row) => row._id} />
       <Modal
        title="Update Campaign Details"
        open={open}
        destroyOnClose
        // onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={null} 
      >
        <Addcampaign campaignData={currentCampaignData} triggerClose={handleOk}/>
      </Modal>
      <Modal
        title="Confirm Raffle"
        open={showPopup}
        destroyOnClose
        onOk={handleConfirmRaffle}
        onCancel={()=>{ setShowPopup(false)}}
      >
        Are you sure to {modalText} the raffle?
      </Modal>
     
      </div>
  </Layout>
}

export default Campaigns;
