import { createSlice } from "@reduxjs/toolkit";

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    loading: false,
    rloading:false
  },
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    showLoadingR: (state) => {
      state.rloading = true;
    },
    hideLoadingR: (state) => {
      state.rloading = false;
    },
  },
});

export const { showLoading, hideLoading,showLoadingR, hideLoadingR } = alertsSlice.actions;
