import React, { useEffect, useState } from "react";
import "../layout.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge } from "antd";
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, Row, Col } from 'antd';

const { Header, Content, Footer } = Layout;

function LayoutC({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const [backgroundColor, setBackground] = useState("");
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState('/');

  const onMenuClick = (e) => {    
    setCurrent(e.key);
    if (e.key === '/logout') {
      localStorage.clear();
      navigate("/");
      window.location.reload()
    }
    if (e.key === '/login') {
      localStorage.clear();
      navigate("/login");
    }
  };
 

  const listenScrollEvent = e => {
    if (window.scrollY > 350) {
      setBackground('#212529')
    } else {
      if( window.location.pathname === "/")
      setBackground('#212529')
    }
  }

  useEffect(() => {
    if(backgroundColor === "" && window.location.pathname !== "/"){
      setBackground('#212529')
    }
    if(backgroundColor === "" && window.location.pathname === "/"){
      setBackground('#212529')
    }
    
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  });

  const userMenu = [
    {
      name: "Home",
      path: "/",
    }
  ];
  const userLoggedinMenu = [
    {
      name:"Compaign Registration",
      path: "/booking",
    },
    {
      name: "Logout",
      path: '/logout',
    }
  ]

  const adminMenu = [
    {
      name: "Campaigns",
      path: "/admin/campaignList",
      icon: "ri-user-line",
    },
    {
      name: "Registrations",
      path: "/admin/campaignRegistrationList",
      icon: "ri-user-line",
    },
    {
      name:"Campaign Registration",
      path: "/booking",
      icon: "ri-user-line",
    },
    {
      name: "Users",
      path: "/admin/userslist",
      icon: "ri-user-line",
    },
    {
      name: "Shops",
      path: "/admin/shopList"
    },
    {
      name: "Config",
      path: "/admin/config"
    },
    {
      name: "Logout",
      path: '/logout',
    },
  ];

  const menuToBeRendered = user? (user.isAdmin ? adminMenu : [...userMenu, ...userLoggedinMenu]) : userMenu;
  const role = user?.isAdmin ? "Admin" : "Agent";
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <div className="main">
      <Layout>
        <Header style={{ position: 'fixed', top: 0, zIndex: 1, width: '100%', background: backgroundColor }}>
          <div
            style={{
              float: 'left',
              width: "auto",
              height: 32
            }}
          >
            <a href={window.location.origin}>
              <img src="/logo.png" alt="Deer Fields" className="logo-1" style={{ width: "150px" }} /> 
            </a>
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            onClick={(e) => onMenuClick(e)}
            selectedKeys={location.pathname}
            className="menu-top"
            items={menuToBeRendered.map((menuItem, index) => {
              let isActive = location.pathname === menuItem.path;
                return ({
                  key: menuItem.path,
                  label: <div className={`menu-item ${isActive && "active-menu-item"} hmenu-item`}>
                    {menuItem.path !== "" && <Link to={menuItem.path}>{menuItem.name}</Link>}
                  </div>
                })
             
             
            })}
          />
          {user &&  <div className="name-role">Welcome {user && user.name} - {role}</div>}
         
        </Header>
      
        <Content className="site-layout">
            <div className={current === "/" ? "body": "body body-content"}>{children}</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <div>© Copyright 2023 - Mall Operations</div>
        </Footer>
      </Layout>
    </div>
  );
}

export default LayoutC;
